
import { getInterventionTypesRequest, getProjectInterventionTypesRequest } from "@/services/InterventionService"
import { Vue } from "vue-class-component"

export default class InterventionsIndividual extends Vue {
	headline = "Interventionskontakte konfigurieren"
	alert: any = {
		text: 'Hier steht dann der Text von Martin',
		class: 'alert-danger'
	}
	choice: any = {
		items: []
	}
	interventonTypes: any = {}
	
	get objectId() {
		return this.$route.params.id
	}
	
	nextStep(type: any) {
		this.$router.push({ name: 'ContactConfigProcessIndividual', params: { id: this.objectId, type: type } })
	}
	
	beforeMount() {
		getInterventionTypesRequest().then((res: any) => {
			if ( res.status === "OK" ) {
				this.interventonTypes = res.result
			}
		})
	}
	
	mounted() {
		getProjectInterventionTypesRequest(this.objectId).then((res: any) => {
			if ( res.status === 'OK' ) {
				this.choice.items = res.result
			}
		})
	}
}
